import 'froala-editor/js/plugins/colors.min';
import 'froala-editor/js/plugins/image.min';
import 'froala-editor/js/plugins/link.min';
import 'froala-editor/js/plugins/lists.min';
import 'froala-editor/js/plugins/paragraph_format.min';
import 'froala-editor/js/plugins/quick_insert.min';
import 'froala-editor/js/plugins/word_paste.min';
import 'froala-editor/js/plugins/url.min';
import 'froala-editor/js/plugins/align.min';
import 'froala-editor/js/plugins/table.min';
import 'froala-editor/js/plugins/code_view.min';
import 'froala-editor/js/plugins/emoticons.min';
import 'froala-editor/js/plugins/draggable.min';
import 'froala-editor/js/plugins/video.min';

import FroalaEditor, { FroalaOptions, GenericObject } from 'froala-editor';
import { getActor } from 'services/global-actor-storage';
import { fqApiUrl } from 'services/common';
import { PublisherContextData } from 'contexts/publisher';
import { SUMMARIZE_WITH_AI_PLUGIN_NAME } from './SummarizeWithAi/summarize-with-ai';
import { BOX_FROALA_PLUGIN_NAME } from './BoxPlugin';

export type Options = Partial<FroalaOptions>;

const KINDS = ['simple-text', 'rich-text', 'plain-text', 'html'] as const;
type Kind = typeof KINDS[number];

function isKind(kind: Kind | unknown): kind is Kind {
  return ((KINDS as unknown) as string[]).includes(`${kind}`);
}

function toValidKind(kind: Kind | unknown): Kind {
  if (isKind(kind)) return kind;
  return 'simple-text';
}

export function makeEditorOptions(
  kind: Kind | unknown,
  overrides: Options
): Options {
  return {
    ...{
      'simple-text': simpleTextOptions,
      'plain-text': plainTextOptions,
      'rich-text': richTextOptions,
      html: htmlOptions,
    }[toValidKind(kind)],
    ...overrides,
  };
}

export async function addImagePlugin(
  options: Options,
  pid: number | string,
  pauseAutosave: PublisherContextData['pauseAutosave'],
  unpauseAutosave: PublisherContextData['unpauseAutosave']
): Promise<Options> {
  const rmImg = (items: string[] = []) =>
    items.filter((item) => item !== 'img');
  const actor = getActor();
  function prepareFluidImage(this: FroalaEditor, img: HTMLImageElement) {
    img.classList.add('fluid-image');
    img.classList.add(`actual-width-${img.naturalWidth}`);
    // Calculate the image width as a percentage of the editor width
    const maxImageWidth = 300;
    const imageWidth = Math.min(maxImageWidth, img.width);
    const editorWidth = this.$el.width() || img.width;
    const widthPct = Math.min(100, (imageWidth / editorWidth) * 100);
    img.style.setProperty('width', `${Math.round(widthPct)}%`);
  }

  return {
    ...options,
    imagePaste: true,
    imageResizeWithPercent: true,
    imageUpload: true,
    pluginsEnabled: [...(options.pluginsEnabled ?? []), 'image', 'draggable'],
    pasteDeniedTags: rmImg(options.pasteDeniedTags),
    wordDeniedTags: rmImg(options.wordDeniedTags),
    imageUploadURL: fqApiUrl(`/samba/programs/${pid}/image_uploads`),
    imageUploadParams: { authenticity_token: undefined },
    imageUploadMethod: 'POST',
    imageMaxSize: 10 * 1024 * 1024, // 10MB
    imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'webp', 'tiff'],
    imageStyles: {
      'fr-rounded': 'Rounded',
      'fr-bordered': 'Bordered',
    },
    imageEditButtons: [
      'imageReplace',
      'imageAlign',
      'imageRemove',
      '|',
      'imageLink',
      'linkOpen',
      'linkEdit',
      'linkRemove',
      '-',
      'imageDisplay',
      'imageStyle',
      'imageAlt',
      'imageSize',
    ],
    requestWithCredentials: false,
    requestHeaders: ((actor?.headers || {}) as unknown) as GenericObject<
      string
    >,
    events: {
      ...options.events,

      // Triggered when an image is dropped into the editor
      'image.inserted': function imageInserted(this: FroalaEditor, $img) {
        // Froala's typing for the img argument is incorrect
        // This re-types img to what it actually is
        const img = ($img as JQuery<HTMLImageElement>)[0];
        prepareFluidImage.call(this, img);
        unpauseAutosave();
      },
      'image.beforeUpload': function beforeUpload(_img) {
        pauseAutosave();
        return true;
      },
      'image.error': function onError(_error) {
        unpauseAutosave();
      },
      // Triggered when an image is pasted into the editor
      'paste.afterCleanup': function pasteAfterCleanup(
        this: FroalaEditor,
        html: string
      ) {
        const resultingHtml =
          options.events?.['paste.afterCleanup']?.call(this, html) ?? html;

        const { body } = new DOMParser().parseFromString(
          resultingHtml,
          'text/html'
        );
        body
          .querySelectorAll<HTMLImageElement>('img:not(.fluid-image)')
          .forEach(prepareFluidImage.bind(this));

        return body.innerHTML;
      },
      // Triggered when text is pasted in rich text editor
      'paste.after': function pasteAfter(this: FroalaEditor) {
        const classList = this.$oel.get(0)?.classList;
        if (classList && classList.contains('fr-inline')) {
          const content = this.html.get();
          const modifiedContent = content.replace(/&nbsp;/g, ' ');
          this.html.set(modifiedContent);
          this.selection.restore();
        }
      },
    },
  };
}

// Froala editors come in different flavors and
// all of them share this same starting point.
const BASE_OPTIONS: Options = {
  key:
    process.env.REACT_APP_FROALA_EDITOR_KEY_V3 ||
    'SDB17hD9C4A3E4C3A2gKTRe2CG1PGe1DESAe1Kg1EBC1Pe2TKoF4I4B3A9A3B5F4B2B3H4==',
  toolbarInline: true,
  charCounterCount: false,
  htmlRemoveTags: ['script', 'style', 'base'],
  initOnClick: true,
  imagePaste: false,
  pastePlain: false,
  pasteAllowedStyleProps: ['text-decoration', 'font-weight', 'font-style'],
  pasteDeniedAttrs: ['id', 'class'],
  pasteDeniedTags: ['img', 'style'],
  wordPasteKeepFormatting: true,
  wordAllowedStyleProps: ['text-decoration', 'font-weight', 'font-style'],
  wordDeniedAttrs: ['class', 'id', 'style'],
  wordDeniedTags: ['img', 'style'],
  wordPasteModal: false,
  imageUpload: false,
  zIndex: 100,
  // workaround for broken font https://github.com/froala/wysiwyg-editor/issues/4795
  fontFamilyDefaultSelection: 'Font Family',
  fontSizeDefaultSelection: 'Font Size',
};

const colors: string[] = [
  '#61BD6D',
  '#1ABC9C',
  '#54ACD2',
  '#2C82C9',
  '#9365B8',
  '#475577',
  '#CCCCCC',

  '#41A85F',
  '#00A885',
  '#3D8EB9',
  '#2969B0',
  '#553982',
  '#28324E',
  '#000000',

  '#F7DA64',
  '#FBA026',
  '#EB6B56',
  '#E25041',
  '#A38F84',
  '#FFFFFF',
  '#EFEFEF',

  '#FAC51C',
  '#F37934',
  '#D14841',
  '#B8312F',
  '#7C706B',
  '#D1D5D8',
  'REMOVE',
];
const colorOptions: Options = {
  colorsBackground: colors,
  colorsText: colors,
};

// `rich-text` This is the primary use-case for editing rich-text. It has goodies.
const richTextOptions: Options = {
  ...BASE_OPTIONS,
  ...colorOptions,
  pluginsEnabled: [
    'link',
    'lists',
    'paragraphFormat',
    'colors',
    'quickInsert',
    'url',
    'wordPaste',
    'align',
    'table',
    'emoticons',
    'liquid',
    'horizontalRule',
    SUMMARIZE_WITH_AI_PLUGIN_NAME,
    BOX_FROALA_PLUGIN_NAME,
  ],
  tableStyles: {
    'fr-none-borders': 'No Borders',
    'alternate-rows': 'Alternate Rows',
  },
  quickInsertButtons: ['ol', 'ul', 'table'],
  toolbarButtons: [
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    'textColor',
    'backgroundColor',
    'insertLink',
    'insertImage',
    'alignLeft',
    'alignCenter',
    'alignRight',
    'outdent',
    'indent',
    'formatOLSimple',
    'formatUL',
    'paragraphFormat',
    'liquid',
    'insertTable',
    'emoticons',
    'subscript',
    'superscript',
    'horizontalRule',
    SUMMARIZE_WITH_AI_PLUGIN_NAME,
    BOX_FROALA_PLUGIN_NAME,
  ],
  paragraphFormat: {
    H1: 'Headline',
    H2: 'Subhead',
    H3: 'Title',
    H4: 'Subtitle',
    H5: 'Caption',
    N: 'Paragraph',
  },
  emoticonsStep: 4,
  emoticonsUseImage: false,
  toolbarVisibleWithoutSelection: true,
};

// `simple-text` These will have a limited set of options, ie no lists.
// The user will still want to be able to edit them inline. This is used for headlines
// and other basic text-formatting situations.
const simpleTextOptions: Options = {
  ...BASE_OPTIONS,
  ...colorOptions,
  pluginsEnabled: ['link', 'colors', 'align', 'emoticons', 'liquid'],
  toolbarButtons: [
    'bold',
    'italic',
    'underline',
    'insertLink',
    'textColor',
    'alignLeft',
    'alignCenter',
    'alignRight',
    'outdent',
    'indent',
    'liquid',
    'emoticons',
    SUMMARIZE_WITH_AI_PLUGIN_NAME,
  ],
  emoticonsStep: 4,
  emoticonsUseImage: false,
  toolbarVisibleWithoutSelection: true,
};

// `plain-text` Using froala to get inline-editing, but without froala features.
const plainTextOptions: Options = {
  ...BASE_OPTIONS,
  pluginsEnabled: [],
  toolbarButtons: [],
};

// `html` Similar to plain text, but for iframes
const htmlOptions: Options = {
  ...plainTextOptions,
  htmlDoNotWrapTags: ['iframe'],
  pluginsEnabled: ['codeView'],
};
