import * as React from 'react';
import { UseMutateFunction, useMutation } from 'react-query';
import { useProgram } from 'contexts/program';
import { DateTime } from 'luxon';
import { useFlashMessage } from 'contexts/flasher';
import { PublicationState } from 'models/content';
import {
  DEFAULT_ERROR_MESSAGE,
  EDIT_PERMISSION_WARNING,
} from 'models/flash-message';
import { isEditable, Post } from 'models/publisher/post';
import { upsert } from 'services/api-post';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { useAutosave } from './autosave';
import { useFieldVariables } from './publisher/useFieldVariables';
import { useChannelValidator } from './useChannelValidator';
import { MAX_CONTENT_SIZE_IN_MB } from '../utility/constants';
import { useFeatureFlagsQuery } from './feature-flags';

export type PostStatus = {
  isSaving: boolean;
  autosaveScheduled: boolean;
  lastModified?: DateTime;
  lastSaveSuccess?: DateTime;
  error?: 'unauthorized' | 'not-found';
  isSizeLimitExceeded?: boolean;
  currentSize?: number;
};

export const defaultPostStatus: PostStatus = {
  isSaving: false,
  autosaveScheduled: false,
};

export type UsePersistPost = {
  save: (
    options?: Partial<{
      shouldValidate: boolean;
      publicationState: PublicationState;
      post: Post;
      onSuccess: () => void;
      onError: (error: Error) => void;
      callback: (post?: Post) => void;
    }>
  ) => void;
  autosave: ReturnType<typeof useAutosave> & {
    pause: () => void;
    unpause: () => void;
  };
  status: PostStatus;
};

const checkContentSizeLimit = (
  blocks: Post['blocks'] | undefined,
  setIsSizeLimitExceeded: React.Dispatch<React.SetStateAction<boolean>>,
  setCurrentSize: React.Dispatch<React.SetStateAction<number>>,
  maxContentSizeInMB: number
) => {
  const contentSizeInBytes = blocks
    ? new Blob([JSON.stringify(blocks)]).size
    : new Blob(['']).size;

  const contentSizeInMB = Math.ceil(contentSizeInBytes / (1024 * 1024));
  const displayContentSize = Math.round(contentSizeInBytes / (1024 * 1024));
  setCurrentSize(displayContentSize);

  const isExceeded = contentSizeInMB >= maxContentSizeInMB;
  setIsSizeLimitExceeded(isExceeded);
  return isExceeded;
};

export const usePersistPost = (
  currentPost: Post | undefined,
  setPost: React.Dispatch<React.SetStateAction<Post | undefined>>,
  readOnly: boolean
): UsePersistPost => {
  const { id: programId } = useProgram();
  const isCampaignLimitExceededFlagEnabled = !!useFeatureFlagsQuery(
    programId,
    'Studio.Publish.CampaignLimitExceeded'
  ).data?.value;
  const validator = useChannelValidator();
  const { setFlashMessage } = useFlashMessage();
  const [lastSaveSuccess, setLastSaveSuccess] = React.useState<
    DateTime | undefined
  >(
    currentPost?.content?.updatedAt
      ? DateTime.fromISO(currentPost?.content.updatedAt)
      : undefined
  );

  React.useEffect(() => {
    if (currentPost?.content?.updatedAt && lastSaveSuccess === undefined)
      setLastSaveSuccess(DateTime.fromISO(currentPost?.content.updatedAt));
  }, [currentPost?.content?.updatedAt, setLastSaveSuccess, lastSaveSuccess]);

  const onSaveSuccess = React.useCallback(
    (postData: Post) => {
      setPost((current) => {
        // This sets the post.content to what is returned from the server in order to get the content.id in there,
        // but it does not overwrite anything that the user might be editing.
        // This may lead to interesting bugs, where client and server state get out of sync.
        // ...like maybe for example, auto saving at the wrong time...
        if (!current) return postData;
        return {
          ...current,
          content: postData.content,
          settings: {
            ...current.settings,
            audiencesCopy: postData.settings.audiencesCopy,
            customSlugs: postData.settings.customSlugs,
          },
        };
      });

      setLastSaveSuccess(DateTime.now());
    },
    [setPost, setLastSaveSuccess]
  );

  const [isMutateLoading, mutate] = usePostMutation(onSaveSuccess, {
    isAutosave: false,
  });
  const [isAutoSaving, autoMutate] = usePostMutation(onSaveSuccess, {
    isAutosave: true,
  });

  const isLoading = isMutateLoading || isAutoSaving;

  const [autosavePaused, setAutosavePaused] = React.useState(false);
  const pauseAutosave = React.useCallback(() => setAutosavePaused(true), []);
  const unpauseAutosave = React.useCallback(() => setAutosavePaused(false), []);
  const [isSizeLimitExceeded, setIsSizeLimitExceeded] = React.useState(false);
  const [currentSize, setCurrentSize] = React.useState(0);

  React.useEffect(() => {
    if (currentPost && isCampaignLimitExceededFlagEnabled) {
      checkContentSizeLimit(
        currentPost.blocks,
        setIsSizeLimitExceeded,
        setCurrentSize,
        MAX_CONTENT_SIZE_IN_MB
      );
    }
  }, [currentPost, isCampaignLimitExceededFlagEnabled]);

  const canAutoSave =
    !autosavePaused &&
    currentPost &&
    !readOnly &&
    currentPost.content.publicationState === 'draft' &&
    currentPost.meta.editable &&
    !isSizeLimitExceeded;

  const unbouncedAutoSave = React.useCallback(
    () =>
      new Promise<void>((resolve) => {
        if (currentPost && canAutoSave) {
          autoMutate(currentPost, { onSuccess: () => resolve() });
        } else {
          resolve();
        }
      }),
    [autoMutate, canAutoSave, currentPost]
  );
  const autosave = useAutosave(unbouncedAutoSave, isLoading);

  const save = React.useCallback<UsePersistPost['save']>(
    ({
      shouldValidate = true,
      publicationState,
      onSuccess,
      onError,
      callback,
      post,
    } = {}) => {
      const postData = post ?? currentPost;
      if (!postData) return;
      if (readOnly) return;
      if (!isEditable(postData)) {
        setFlashMessage(EDIT_PERMISSION_WARNING);
        return;
      }

      // Check if content size is exceeded before proceeding
      if (isCampaignLimitExceededFlagEnabled) {
        const isContentSizeExceeded = checkContentSizeLimit(
          postData.blocks,
          setIsSizeLimitExceeded,
          setCurrentSize,
          MAX_CONTENT_SIZE_IN_MB
        );

        if (isContentSizeExceeded) {
          return;
        }
      }

      const isValid =
        validator.validate({
          settings: postData.settings,
        }) || !shouldValidate;

      if (isValid) {
        mutate(
          {
            ...postData,
            content: {
              ...postData.content,
              publicationState:
                publicationState ?? postData.content.publicationState,
            },
          },
          {
            onSuccess: onSuccess ?? (() => {}),
            onError: (error: Error) => {
              if (onError) onError(error);

              const parsedError =
                error.message && JSON.parse(error.message || '{}');
              const message =
                parsedError?.errors?.length > 0
                  ? parsedError.errors[0]
                  : 'An error occurred.';

              setFlashMessage({ timeout: 5000, severity: 'error', message });
            },
            onSettled: callback,
          }
        );
      } else {
        setFlashMessage({
          severity: 'error',
          message: DEFAULT_ERROR_MESSAGE,
          details: validator.errors.join(' '),
        });
      }
    },
    [
      currentPost,
      readOnly,
      validator,
      setFlashMessage,
      mutate,
      isCampaignLimitExceededFlagEnabled,
    ]
  );

  return {
    autosave: { ...autosave, pause: pauseAutosave, unpause: unpauseAutosave },
    save,
    status: {
      isSaving: isLoading,
      autosaveScheduled: autosave.autosaveScheduled,
      lastSaveSuccess,
      isSizeLimitExceeded,
      currentSize,
    },
  };
};

function usePostMutation(
  onSuccess: (post: Post) => void,
  options: { isAutosave: boolean }
): [boolean, UseMutateFunction<Post, Error, Post>] {
  const { id: programId } = useProgram();
  const { fromPost } = useFieldVariables();
  const { settings } = useSettings();

  const { isLoading, mutate } = useMutation<Post, Error, Post>(
    ['publisher/content/save'],
    async (post: Post) =>
      upsert(
        {
          programId,
          post,
          variables: fromPost({
            ...post,
            settings: {
              ...post.settings,
              deliveryPageVersion: settings.deliveryPageVersion,
            },
          }),
        },
        options
      ),
    { onSuccess }
  );
  return [isLoading, mutate];
}
